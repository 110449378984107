import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase"; // Firebaseの初期化設定が含まれているモジュールをインポート
import "./MemberInfo.scss";

const MemberEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [member, setMember] = useState({
    accountname: "",
    email: "",
    tel_num: "",
    rank: "",
    profile: "",
    photo: "",
    video: "",
    admin: false,
    administrator: false,
    author: {
      username: "",
      id: "",
    },
  });
  const [docId, setDocId] = useState(""); // ドキュメントIDを保存するためのステート
  const [error, setError] = useState("");
  const isAdmin = localStorage.getItem("administrator") === "true";

  useEffect(() => {
    const fetchMember = async () => {
      try {
        // author.idがURLのidと一致するメンバーを取得
        const q = query(
          collection(db, "members"),
          where("author.id", "==", id)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setMember(doc.data());
            setDocId(doc.id); // ドキュメントIDを保存
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (id) {
      fetchMember();
    }
  }, [id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setMember((prevMember) => ({
          ...prevMember,
          author: {
            username: currentUser.displayName || "Anonymous",
            id: currentUser.uid,
          },
        }));
      } else {
        setError("ユーザーが認証されていません。ログインしてください。");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMember((prevMember) => ({
      ...prevMember,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      setError("ユーザーが認証されていません。ログインしてください。");
      return;
    }
    try {
      if (docId) {
        // docIdが存在する場合のみ更新
        const memberRef = doc(db, "members", docId);
        await updateDoc(memberRef, member); // memberオブジェクトを渡す
        console.log("Member updated successfully");
        setError(""); // エラーをクリア
        navigate("/eventlist");
      } else {
        setError("更新するドキュメントが存在しません。");
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      setError(`データの更新中にエラーが発生しました。詳細: ${error.message}`);
    }
  };

  return (
    <div className="Container-member">
      <h2>会員情報</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="formField">
          <label>アカウントネーム:</label>
          <input
            type="text"
            name="accountname"
            value={member.accountname}
            onChange={handleChange}
          />
        </div>
        <div className="formField">
          <label>連絡用メールアドレス:</label>
          <input
            type="email"
            name="email"
            value={member.email}
            onChange={handleChange}
          />
        </div>
        <div className="formField">
          <label>連絡用携帯電話番号:</label>
          <input
            type="tel"
            name="tel_num"
            value={member.tel_num}
            onChange={handleChange}
          />
        </div>
        <div className="formField">
          <label>写真:</label>
          <input
            type="text"
            name="photo"
            value={member.photo}
            onChange={handleChange}
          />
        </div>
        <div className="formField">
          <div className="checkbox-container">
            {isAdmin && (
              <div className="formField">
                <label>管理者認定:</label>
                <input
                  className="checkbox-mc"
                  type="checkbox"
                  name="admin"
                  checked={member.admin}
                  onChange={handleChange}
                />
                <label>管理者権限:</label>
                <input
                  className="checkbox-mc"
                  type="checkbox"
                  name="administrator"
                  checked={member.administrator}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>

        <button type="submit">送信</button>
      </form>
    </div>
  );
};

export default MemberEdit;
