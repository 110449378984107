// EventSelect.jsx

import { format, parseISO } from "date-fns";
import { ja } from "date-fns/locale/ja";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import "./EventSelect.scss";

const EventSelect = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // イベント一覧を取得
      const eventCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        starttime: parseISO(doc.data().starttime), // ここでstarttimeをDateオブジェクトに変換
      }));
      // 日付の遅い順にソート
      eventList.sort((a, b) => b.starttime - a.starttime);
      setEvents(eventList);
    };

    fetchData();
  }, [navigate]);

  return (
    <div className="eventSelect-container">
      {events.length === 0 ? (
        <>
          <button onClick={() => navigate("/eventform")}>新規日程入力</button>
        </>
      ) : (
        <>
          <h1>再利用可能なイベント一覧</h1>
          <table>
            <thead className="title">
              <tr>
                <th className="event-select-date event_title">開催日時</th>
                <th className="event-select-title event_title">タイトル</th>
                <th className="event-select-location event_title">開催場所</th>
                <th className="event-select-reuse event_title">再利用する</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, index) => (
                <tr key={index}>
                  <td>
                    {format(event.starttime, "M /d（E）HH:mm ", {
                      locale: ja,
                    })}
                  </td>
                  <td>{event.title}</td>
                  <td>{event.site_region}</td>
                  <td className="table-cell">
                    <button
                      onClick={() => navigate(`/eventcreate/${event.id}`)}
                      style={{
                        fontSize: "18px",
                        padding: "1px 2px",
                        fontWeight: "bolder",
                      }}
                    >
                      再利用する
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default EventSelect;
