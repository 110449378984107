// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDplD4IjfqDqx90QVAEU_zGDeLKp-fOxlw",
  authDomain: "circle-homepage-unique-id.firebaseapp.com",
  projectId: "circle-homepage-unique-id",
  storageBucket: "circle-homepage-unique-id.appspot.com",
  messagingSenderId: "1021487158495",
  appId: "1:1021487158495:web:fa2fa6fb38736cc8a2056a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
