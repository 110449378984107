import { EventContext } from "Contexts/EventContext";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";
import { auth, db } from "../firebase";
import "./EventCancel.scss";

function EventCancel() {
  const { eventId, eventMemberId } = useParams();
  const navigate = useNavigate();

  const [event, setEvent] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [accountName, setAccountName] = useState("");
  const { eventList } = useContext(EventContext);
  const { membersList } = useContext(MemberContext); // Contextからメンバー情報を取得

  useEffect(() => {
    const currentEvent = eventList.find((e) => e.id === eventId);
    setEvent(currentEvent);

    const fetchAccountName = async () => {
      try {
        const eventMemberRef = doc(db, "event_members", eventMemberId);
        const eventMemberDoc = await getDoc(eventMemberRef);

        if (eventMemberDoc.exists()) {
          const eventMemberData = eventMemberDoc.data();
          const memberId = eventMemberData.memberId;

          const member = membersList.find((m) => m.author.id === memberId);

          if (member) {
            setAccountName(member.accountname);
          } else {
            console.log("No matching member found in 'membersList'");
          }
        } else {
          console.log("No such event member document!");
        }
      } catch (error) {
        console.error("Error fetching account name:", error);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        fetchAccountName();
      }
    });

    return () => unsubscribe();
  }, [eventList, membersList, eventId, eventMemberId]);

  const handleRemoveParticipant = async () => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      const eventMemberRef = doc(db, "event_members", eventMemberId);
      const eventMemberDoc = await getDoc(eventMemberRef);

      if (eventMemberDoc.exists()) {
        await deleteDoc(eventMemberRef);
        console.log("イベントからのユーザーの削除が正常に完了しました");
        navigate("/eventlist");
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error(
        "イベントからのユーザーの削除中にエラーが発生しました:",
        error
      );
    }
  };

  if (!event) {
    return <p>Loading...</p>;
  }

  return (
    <div className="eventcancel-container">
      <div className="content">
        <div className="title3">
          <h2>参加取消画面</h2>
          <div className="close-button3" onClick={() => navigate("/eventlist")}>
            X
          </div>
        </div>
        <div>
          <label>タイトル </label>
          <div className="cancel-event">{event.title}</div>
        </div>
        <div>
          <label>開催日時 </label>
          <div className="cancel-event">
            {" "}
            {format(event.starttime, "M /d（E）HH:mm ", {
              locale: ja,
            })}
          </div>
        </div>
        <div>
          <label>キャンセルする会員名</label>
          <div className="cancel-event">
            {accountName ? accountName : "検索中"}
          </div>
        </div>
        <button onClick={handleRemoveParticipant}>取り消し実行</button>
      </div>
    </div>
  );
}

export default EventCancel;
