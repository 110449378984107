import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { auth, db } from "../firebase"; // Firebaseの初期化設定が含まれているモジュールをインポート
import "./MemberInfo.scss";

const MemberInfo = () => {
  const { id } = useParams();
  const [member, setMember] = useState({
    accountname: "",
    email: "",
    tel_num: "",
    rank: "",
    profile: "",
    photo: "",
    video: "",
    administrator: false,
    author: {
      username: "",
      id: "",
    },
  });
  const [error, setError] = useState("");
  const [userUid, setUserUid] = useState("");
  console.log("userInfouid" + userUid);

  useEffect(() => {
    const storedUserUid = localStorage.getItem("userUid");
    if (storedUserUid) {
      setUserUid(storedUserUid);
    }
    const fetchMember = async () => {
      try {
        // author.idがURLのidと一致するメンバーを取得
        const q = query(
          collection(db, "members"),
          where("author.id", "==", id)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setMember(doc.data());
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (id) {
      fetchMember();
    }
  }, [id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setMember((prevMember) => ({
          ...prevMember,
          author: {
            username: currentUser.displayName || "Anonymous",
            id: currentUser.uid,
          },
        }));
      } else {
        setError("ユーザーが認証されていません。ログインしてください。");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="Container-member">
      <h2>会員情報</h2>
      {error && <p className="error">{error}</p>}
      <div className="formField">
        <label>アカウントネーム:</label>
        <p>{member.accountname}</p>
      </div>
      <div className="formField">
        <label>連絡用メールアドレス:</label>
        <p>{member.email}</p>
      </div>
      <div className="formField">
        <label>連絡用携帯電話番号:</label>
        <p>{member.tel_num}</p>
      </div>
      <div className="formField">
        <label>種別:</label>
        <p>{member.rank}</p>
      </div>
      <div className="formField">
        <label>プロフ:</label>
        <p>{member.profile}</p>
      </div>
      <div className="formField">
        <label>写真:</label>
        <p>{member.photo}</p>
      </div>
      <div className="formField">
        <label>ビデオ:</label>
        <p>{member.video}</p>
      </div>
      {/* <div className="formField">
        <label>ID:</label>
        <p>{member.author.id}</p>
      </div> */}
      <div className="formField">
        <label>管理権限:</label>
        <p>{member.administrator ? "有り" : "無し"}</p>
      </div>

      <div>
        {member.author.id == userUid && (
          <Link to={`/memberedit/${member.author.id}`}>情報更新</Link>
        )}
      </div>
    </div>
  );
};

export default MemberInfo;
