import EventCancel from "Components/EventCancel";
import EventCreate from "Components/EventCreate";
import EventDetail from "Components/EventDetail";
import EventEdit from "Components/EventEdit";
import EventForm from "Components/EventForm";
import EventList from "Components/EventList";
import EventMemberManagement from "Components/EventMemberManagement";
import EventSelect from "Components/EventSelect";
import Links from "Components/Links";
import MemberEdit from "Components/MemberEdit";
import MemberInfo from "Components/MemberInfo";
import MemberList from "Components/MemberList";
import Test from "Components/Test";
import { EventProvider } from "Contexts/EventContext";
import { EventMembersProvider } from "Contexts/EventMembersContext";
import { MemberProvider } from "Contexts/MemberContext";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import EmailLogin from "./Components/EmailLogin";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Logout from "./Components/Logout";
import MemberCreate from "./Components/MemberCreate";
import Navbar from "./Components/Navbar";
import ResetEmailPassword from "./Components/ResetEmailPassword";
import SignUpForm from "./Components/SignUpForm";

function App() {
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem("isAuth"));
  const [accountName, setAccountName] = useState("");
  const [administrator, setAdministrator] = useState(
    JSON.parse(localStorage.getItem("administrator"))
  );

  useEffect(() => {
    const storedAccountName = localStorage.getItem("accountName");
    if (storedAccountName) {
      setAccountName(storedAccountName);
    }

    const storedAdministrator = localStorage.getItem("administrator");
    if (storedAdministrator) {
      setAdministrator(JSON.parse(storedAdministrator));
    }
  }, []);

  return (
    <Router>
      <EventMembersProvider>
        <EventProvider>
          <MemberProvider>
            {" "}
            {/* EventProviderでラップ */}
            <div className="fullscreen-background"></div>{" "}
            <Navbar isAuth={isAuth} accountName={accountName} />
            <Routes>
              <Route
                path="/"
                element={
                  <Home setIsAuth={setIsAuth} setAccountName={setAccountName} />
                }
              />
              <Route
                path="/login"
                element={
                  <Login
                    setIsAuth={setIsAuth}
                    setAccountName={setAccountName}
                    setAdministrator={setAdministrator}
                  />
                }
              />
              <Route
                path="/emaillogin"
                element={<EmailLogin setIsAuth={setIsAuth} />}
              />
              <Route path="/resetpassword" element={<ResetEmailPassword />} />
              <Route
                path="/logout"
                element={<Logout setIsAuth={setIsAuth} />}
              />
              <Route
                path="/signupform"
                element={<SignUpForm setIsAuth={setIsAuth} />}
              />
              <Route path="/link" element={<Links />} />
              <Route
                path="/eventlist"
                element={<EventList isAuth={isAuth} />}
              />
              <Route
                path="/membercreate"
                element={<MemberCreate isAuth={isAuth} />}
              />
              <Route
                path="/memberlist"
                element={<MemberList isAuth={isAuth} />}
              />
              <Route
                path="/memberinfo/:id"
                element={<MemberInfo isAuth={isAuth} />}
              />
              <Route
                path="/memberedit/:id"
                element={<MemberEdit isAuth={isAuth} />}
              />
              <Route
                path="/eventcreate"
                element={<EventCreate isAuth={isAuth} />}
              />
              <Route
                path="/eventselect"
                element={<EventSelect isAuth={isAuth} />}
              />
              <Route
                path="/evenmembermanagement"
                element={<EventMemberManagement isAuth={isAuth} />}
              />
              <Route
                path="/eventcreate/:id"
                element={<EventCreate isAuth={isAuth} />}
              />
              <Route
                path="/eventcancel/:eventId/:eventMemberId"
                element={<EventCancel isAuth={isAuth} />}
              />
              <Route
                path="/eventform"
                element={<EventForm isAuth={isAuth} />}
              />
              <Route path="/test" element={<Test isAuth={isAuth} />} />
              <Route
                path="/eventedit/:id"
                element={<EventEdit isAuth={isAuth} />}
              />
              <Route
                path="/eventdetail/:id"
                element={<EventDetail isAuth={isAuth} />}
              />
            </Routes>
          </MemberProvider>
        </EventProvider>
      </EventMembersProvider>
    </Router>
  );
}

export default App;
