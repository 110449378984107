import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EmailLogin.scss";

const EmailLogin = ({ setIsAuth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailLogin = () => {
    navigate("/resetpassword");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      // ログイン成功時の処理
      console.log("ログイン成功:");
      localStorage.setItem("isAuth", true);
      setIsAuth(true);
      // ユーザー情報を取得
      const user = auth.currentUser;
      setCurrentUser(user);

      // Firestoreからメンバーリストを取得
      const db = getFirestore();
      const membersCollection = collection(db, "members");
      const membersSnapshot = await getDocs(membersCollection);
      const membersList = membersSnapshot.docs.map((doc) => doc.data());

      // メンバーリストにユーザーのUIDがあるか確認
      const isRegistered = membersList.some(
        (member) => member.author.id === user.uid
      );

      if (!isRegistered) {
        alert(
          "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
        );
        navigate("/membercreate");
        return;
      }

      navigate("/eventlist");
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
          setError(
            "無効なメールアドレスです。正しいメールアドレスを入力してください。"
          );
          break;
        case "auth/user-disabled":
          setError("アカウントが無効です。管理者にお問い合わせください。");
          break;
        case "auth/user-not-found":
          setError("メールアドレスまたはパスワードが間違っています。");
          break;
        case "auth/wrong-password":
          setError(
            "パスワードが間違っています。正しいパスワードを入力してください。"
          );
          break;
        default:
          setError("メールアドレスまたはパスワードが間違っています。");
          break;
      }
      console.error("ログイン失敗:", error);
    }
  };

  return (
    <div className="container-email-login">
      <div className="content">
        <div className="title">
          <p>メールアドレス</p>
        </div>
        <div className="close-button" onClick={() => navigate("/login")}>
          X
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <input
            className="login-input"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <p>パスワード</p>
          <input
            className="login-input"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <button className="login-button" type="submit">
            ログイン
          </button>
        </form>
        {error && (
          <div className="content">
            <p>{error}</p>
          </div>
        )}
      </div>
      <div className="content2">
        <button
          type="button"
          onClick={handleEmailLogin}
          className="login-button"
        >
          パスワードを忘れた
        </button>
      </div>
    </div>
  );
};

export default EmailLogin;
