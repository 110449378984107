import { EventContext } from "Contexts/EventContext";
import { EventMembersContext } from "Contexts/EventMembersContext";
import { MemberContext } from "Contexts/MemberContext";
import { format } from "date-fns";
import { ja } from "date-fns/locale/ja";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { ParticipantList } from "./ParticipantList";
import "./Test.scss";

const Test = () => {
  const { eventList } = useContext(EventContext);
  const { membersList } = useContext(MemberContext);
  const { eventMembersList } = useContext(EventMembersContext);
  const [events, setEvents] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventMembers, setEventMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [userEventParticipation, setUserEventParticipation] = useState({});
  const [participantCounts, setParticipantCounts] = useState({});
  const navigate = useNavigate();
  const [showSaturdayEvents, setShowSaturdayEvents] = useState(false);
  const [showSundayEvents, setShowSundayEvents] = useState(false);
  const [showWeekdayEvents, setShowWeekdayEvents] = useState(false);
  const [enableJoin, setEnableJoin] = useState(false);
  const [skipConfirmation, setSkipConfirmation] = useState(false);

  const isAdmin = localStorage.getItem("administrator") === "true";

  useEffect(() => {
    const user = auth.currentUser;
    setCurrentUser(user);
    setEvents(eventList || []);
    setEventMembers(eventMembersList || []);
    setMembers(membersList || []);

    if (user) {
      const userId = user.uid;

      // ユーザーが参加しているイベントのリストを取得
      const userEventMembers = eventMembersList.filter(
        (member) => member.memberId === userId
      );

      const userEventIds = userEventMembers.map((member) => member.eventId);
      const userEvents = events.filter((event) =>
        userEventIds.includes(event.id)
      );

      setUserEventParticipation(
        userEvents.reduce((acc, event) => {
          acc[event.id] = true;
          return acc;
        }, {})
      );
    }
  }, [eventList, eventMembersList, membersList]);

  // 半角数字を全角数字に変換する関数
  const toFullWidth = (str) =>
    str.replace(/[0-9]/g, (s) => String.fromCharCode(s.charCodeAt(0) + 0xfee0));

  // 現在の時間の翌日を取得
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // 翌日を設定
  tomorrow.setHours(0, 0, 0, 0);

  // 翌日より前のイベントを除外
  const filteredEventList = events.filter(
    (event) => new Date(event.starttime) >= tomorrow
  );

  // 日付の早い順にソート
  filteredEventList.sort(
    (a, b) => new Date(a.starttime) - new Date(b.starttime)
  );

  const handleCheckboxChange = (setter) => () => setter((prev) => !prev);

  const handleJoinEvent = async (eventId) => {
    if (!enableJoin) {
      alert(
        "参加ボタン有効化か確認アラート不要をチェックしてください（後者をチェックした場合、確認アラートの表示が省略できます）"
      );
      return;
    }
    if (!currentUser) {
      alert("ログインしてください");
      navigate("/login");
      return;
    }

    // イベント参加処理（詳細な実装はここに追加する必要があります）
  };

  const filteredEvents = filteredEventList.filter((event) => {
    const dayOfWeek = new Date(event.starttime).getDay();
    if (!showSaturdayEvents && !showSundayEvents && !showWeekdayEvents)
      return true;
    if (showSaturdayEvents && !showSundayEvents && !showWeekdayEvents)
      return dayOfWeek === 6; // 土曜日
    if (!showSaturdayEvents && showSundayEvents && !showWeekdayEvents)
      return dayOfWeek === 0; // 日曜日
    if (!showSaturdayEvents && !showSundayEvents && showWeekdayEvents)
      return dayOfWeek >= 1 && dayOfWeek <= 5; // 平日
    if (showSaturdayEvents && showSundayEvents && !showWeekdayEvents)
      return dayOfWeek === 0 || dayOfWeek === 6; // 土曜日または日曜日
    if (showSaturdayEvents && !showSundayEvents && showWeekdayEvents)
      return dayOfWeek === 6 || (dayOfWeek >= 1 && dayOfWeek <= 5); // 土曜日または平日
    if (!showSaturdayEvents && showSundayEvents && showWeekdayEvents)
      return dayOfWeek === 0 || (dayOfWeek >= 1 && dayOfWeek <= 5); // 日曜日または平日
    return true; // デフォルトで全て表示
  });

  return (
    <div className="eventListContainer">
      <div className="hyodai">
        <h1>イベント一覧</h1>
        <h3>表示する曜日を選択</h3>
        <div className="filter">
          <label>
            <input
              type="checkbox"
              checked={showSaturdayEvents}
              onChange={handleCheckboxChange(setShowSaturdayEvents)}
            />
            土
          </label>
          <label>
            <input
              type="checkbox"
              checked={showSundayEvents}
              onChange={handleCheckboxChange(setShowSundayEvents)}
            />
            日
          </label>
          <label>
            <input
              type="checkbox"
              checked={showWeekdayEvents}
              onChange={handleCheckboxChange(setShowWeekdayEvents)}
            />
            平日
          </label>
          <label>
            <input
              type="checkbox"
              checked={enableJoin}
              onChange={handleCheckboxChange(setEnableJoin)}
            />
            参加ボタン有効化
          </label>
          <label>
            <input
              type="checkbox"
              checked={skipConfirmation}
              onChange={() => {
                setSkipConfirmation((prev) => !prev);
                setEnableJoin((prev) => !prev);
              }}
            />
            確認アラート不要
          </label>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="event_title">開催日</th>
            <th className="event_title hide-on-narrow">開催時間</th>
            <th className="event_title">タイトル</th>
            <th className="event_title hide-on-narrow">開催場所</th>
            <th className="event_title hide-on-narrow">地図</th>
            <th className="event_title hide-on-narrow">面数</th>
            <th className="event_title hide-on-narrow">定員</th>
            {isAdmin && (
              <>
                <th className="event_title hide-on-narrow">申込人数</th>
              </>
            )}
            <th className="event_title hide-on-narrow">表面</th>
            <th className="event_title hide-on-narrow">コート番号</th>
            <th className="event_title hide-on-narrow">参加者</th>
            <th className="event_title">参加</th>
            {isAdmin && (
              <>
                <th className="event_title hide-on-narrow">削除</th>
                <th className="event_title hide-on-narrow">編集</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {filteredEvents.map((event, index) => (
            <tr key={index}>
              <td className="event-date">
                {format(new Date(event.starttime), "M /d（E）HH:mm", {
                  locale: ja,
                })}
              </td>
              <td className="hide-on-narrow" style={{ fontSize: "24px" }}>
                {event.duration_hour}
                {event.duration_minute}
              </td>
              <td>
                <button
                  className="button_title"
                  onClick={() => navigate(`/eventdetail/${event.id}`)}
                >
                  {event.title}
                </button>
              </td>
              <td className="hide-on-narrow">{event.site_region}</td>
              <td className="hide-on-narrow">
                {event.map ? (
                  <a href={event.map} target="_blank" rel="noopener noreferrer">
                    地図を見る
                  </a>
                ) : (
                  "地図情報がありません"
                )}
              </td>
              <td className="hide-on-narrow">
                {event.court_count
                  ? toFullWidth(event.court_count.toString())
                  : "N/A"}
              </td>
              <td className="hide-on-narrow">
                {event.capacity
                  ? toFullWidth(event.capacity.toString())
                  : "N/A"}
              </td>
              {isAdmin && (
                <td className="hide-on-narrow">
                  {participantCounts[event.id]
                    ? toFullWidth(participantCounts[event.id].toString())
                    : "0"}
                </td>
              )}
              <td className="hide-on-narrow">{event.court_surface}</td>
              <td className="hide-on-narrow">
                {event.court_num
                  ? toFullWidth(event.court_num.toString())
                  : "N/A"}
              </td>
              <td className="hide-on-narrow">
                <div className="participantList">
                  <ParticipantList
                    id={`participants-${event.id}`}
                    eventId={event.id}
                    eventMembers={eventMembers}
                    members={members}
                    navigate={navigate}
                    administrator={isAdmin}
                  />
                </div>
              </td>
              <td>
                {userEventParticipation[event.id] ? (
                  <button
                    className="button2"
                    onClick={() => handleJoinEvent(event.id)}
                  >
                    参加予定
                  </button>
                ) : participantCounts[event.id] >= event.capacity ? (
                  <button
                    className="button3"
                    disabled
                    style={{
                      cursor: "not-allowed",
                      backgroundColor: "#a99fe9",
                    }}
                  >
                    定員締め切り
                  </button>
                ) : (
                  <button
                    className="button3"
                    onClick={() => handleJoinEvent(event.id)}
                    style={{
                      backgroundColor: "#ffcc6f",
                    }}
                  >
                    募集中
                  </button>
                )}
              </td>
              {isAdmin && (
                <>
                  <td className="hide-on-narrow">
                    <button
                      style={{
                        backgroundColor: "#fd9812",
                        fontSize: "20px",
                      }}
                      className="button3"
                      onClick={() => handleJoinEvent(event.id)}
                    >
                      削除
                    </button>
                  </td>
                  <td className="hide-on-narrow">
                    <button
                      style={{
                        backgroundColor: "#1eb300",
                        fontSize: "20px",
                      }}
                      onClick={() => navigate(`/eventedit/${event.id}`)}
                    >
                      編集
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Test;
