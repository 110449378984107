import { format, parseISO } from "date-fns";
import { ja } from "date-fns/locale/ja";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import "./EventMemberManagement.scss";

const EventMemberManagement = () => {
  const [events, setEvents] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // イベント一覧を取得
      const eventCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        starttime: parseISO(doc.data().starttime),
      }));

      // 現在の時間の翌日を取得
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);

      // 翌日より前のイベントを除外
      const filteredEventList = eventList.filter(
        (event) => event.starttime >= tomorrow
      );

      // 日付の早い順にソート
      filteredEventList.sort((a, b) => a.starttime - b.starttime);
      setEvents(filteredEventList);

      // イベントメンバー一覧を取得
      const eventMembersCollection = collection(db, "event_members");
      const eventMembersSnapshot = await getDocs(eventMembersCollection);
      const eventMembersList = eventMembersSnapshot.docs.map((doc) =>
        doc.data()
      );
      setEventMembers(eventMembersList);

      // メンバー一覧を取得
      const membersCollection = collection(db, "members");
      const membersSnapshot = await getDocs(membersCollection);
      const membersList = membersSnapshot.docs.map((doc) => doc.data());
      setMembers(membersList);
    };

    fetchData();
  }, [navigate]);

  const handleJoinEvent = async (eventId, currentUser) => {
    // ユーザーのアカウント名を取得
    const user = members.find((member) => member.author.id === currentUser.uid);
    if (!user) {
      alert(
        "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
      );
      navigate("/member");
      return;
    }
    const accountname = user.accountname;

    // イベントメンバーに新しいドキュメントを追加
    await addDoc(collection(db, "event_members"), {
      eventId: eventId,
      memberId: currentUser.uid,
    });

    // ローカルのステートを更新
    setEventMembers((prevEventMembers) => [
      ...prevEventMembers,
      { eventId, memberId: currentUser.uid, accountname },
    ]);

    // Firestoreから最新のデータをフェッチ
    const eventMembersCollection = collection(db, "event_members");
    const eventMembersSnapshot = await getDocs(eventMembersCollection);
    const eventMembersList = eventMembersSnapshot.docs.map((doc) => doc.data());
    setEventMembers(eventMembersList);
  };

  const handleRemoveMember = async (eventId, memberId) => {
    // メンバー情報を取得
    const member = eventMembers.find(
      (member) => member.eventId === eventId && member.memberId === memberId
    );

    if (!member) {
      alert("メンバー情報が見つかりません");
      return;
    }

    // メンバーのアカウント名を取得
    const accountName =
      members.find((m) => m.author.id === member.memberId)?.accountname ||
      "不明";

    // 削除確認ダイアログを表示
    const confirmDelete = window.confirm(
      `${accountName}さんを本当に削除しますか？`
    );
    if (!confirmDelete) return;

    // Firestoreからイベントメンバーのドキュメントを削除
    const eventMembersCollection = collection(db, "event_members");
    const q = query(
      eventMembersCollection,
      where("eventId", "==", eventId),
      where("memberId", "==", memberId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // ローカルのステートを更新
    setEventMembers((prevEventMembers) =>
      prevEventMembers.filter(
        (member) =>
          !(member.eventId === eventId && member.memberId === memberId)
      )
    );
  };

  const handleAddMember = async (eventId, memberId) => {
    // イベント情報を取得
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      alert("イベントが見つかりません");
      return;
    }

    // 現在の参加者数を取得
    const currentParticipantsCount = eventMembers.filter(
      (member) => member.eventId === eventId
    ).length;

    // 定員を超えているか確認
    if (currentParticipantsCount >= event.capacity) {
      alert("定員に達しています。");
      return;
    }

    // メンバー情報を取得
    const member = members.find((m) => m.author.id === memberId);
    if (!member) {
      alert("メンバー情報が見つかりません");
      return;
    }

    // メンバーのアカウント名を取得
    const accountName = member.accountname || "不明";
    console.log(`Account Name: ${accountName}`); // デバッグ用

    // 参加確認ダイアログを表示
    const confirmAdd = window.confirm(`${accountName}さんを参加させますか？`);
    if (!confirmAdd) return;

    // メンバーのアカウント名を取得
    const user = members.find((member) => member.author.id === memberId);
    if (!user) {
      alert("メンバー情報が見つかりません");
      return;
    }
    const accountname = user.accountname;

    // イベントメンバーに新しいドキュメントを追加
    await addDoc(collection(db, "event_members"), {
      eventId: eventId,
      memberId: memberId,
    });

    // ローカルのステートを更新
    setEventMembers((prevEventMembers) => [
      ...prevEventMembers,
      { eventId, memberId, accountname },
    ]);

    setMessage(""); // メッセージをクリア
  };

  const handleAccountNameClick = (eventId, memberId) => {
    // メンバーを削除または追加する処理を呼び出す
    handleRemoveMember(eventId, memberId);
  };

  return (
    <div className="event-member-management-container">
      <h1 className="event-member-management-title">イベント管理一覧</h1>
      <table className="event-member-management-table">
        <thead className="event-member-management-table-header">
          <tr>
            <th className="event-member-management-table-header-cell">
              開催日時
            </th>
            <th className="event-member-management-table-header-cell">
              タイトル
            </th>
            <th className="event-member-management-table-header-cell">
              参加者
            </th>
            <th className="event-member-management-table-header-cell">
              未参加者
            </th>
            <th className="event-member-management-table-header-cell">
              定員状況
            </th>
            <th className="event-member-management-table-header-cell">
              再利用
            </th>
          </tr>
        </thead>
        <tbody className="event-member-management-table-body">
          {events.map((event, index) => {
            // イベントに参加しているメンバーのアカウント名を取得
            const participants = eventMembers
              .filter((member) => member.eventId === event.id)
              .map((member) => {
                const accountname =
                  members.find((m) => m.author.id === member.memberId)
                    ?.accountname || "不明";
                return (
                  <button
                    key={member.memberId}
                    className="event-member-management-participant-button"
                    onClick={() =>
                      handleAccountNameClick(event.id, member.memberId)
                    }
                  >
                    {accountname}
                  </button>
                );
              });

            // イベントに参加していないメンバーのアカウント名を取得
            const nonParticipants = members
              .filter(
                (member) =>
                  !eventMembers.some(
                    (eventMember) =>
                      eventMember.memberId === member.author.id &&
                      eventMember.eventId === event.id
                  )
              )
              .map((member) => {
                const accountname = member.accountname || "不明";
                return (
                  <button
                    key={member.author.id}
                    className="event-member-management-non-participant-button"
                    onClick={() => handleAddMember(event.id, member.author.id)}
                  >
                    {accountname}
                  </button>
                );
              });

            // 定員に達しているかどうかのチェック
            const currentParticipantsCount = eventMembers.filter(
              (member) => member.eventId === event.id
            ).length;
            const isCapacityFull = currentParticipantsCount >= event.capacity;

            return (
              <tr key={index} className="event-member-management-table-row">
                <td className="event-member-management-table-cell">
                  {format(event.starttime, "M /d（E）HH:mm ", {
                    locale: ja,
                  })}
                </td>
                <td className="event-member-management-table-cell-title">
                  {event.title}
                </td>
                <td className="event-member-management-table-cell">
                  {participants.length > 0 ? participants : "なし"}
                </td>
                <td className="event-member-management-table-cell">
                  {nonParticipants.length > 0 ? nonParticipants : "なし"}
                </td>
                <td className="event-member-management-table-cell">
                  {isCapacityFull ? (
                    <div
                      className="event-member-management-alert event-member-management-alert-capacity-full"
                      style={{
                        cursor: "not-allowed",
                        backgroundColor: "#a99fe9",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      定員締切
                    </div>
                  ) : (
                    <div
                      className="event-member-management-alert event-member-management-alert-open"
                      style={{
                        backgroundColor: "#ffcc6f",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      募集中
                    </div>
                  )}
                </td>

                <td className="event-member-management-table-cell">
                  <button
                    className="event-member-management-reuse-button"
                    onClick={() => navigate(`/eventcreate/${event.id}`)}
                  >
                    再利用
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {message && (
        <div className="event-member-management-alert">{message}</div>
      )}
    </div>
  );
};

export default EventMemberManagement;
