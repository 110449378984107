import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MemberContext } from "../Contexts/MemberContext";
import "./Navbar.scss";

const Navbar = ({ isAuth }) => {
  const [menuActive, setMenuActive] = useState(false);
  const { currentUserInfo } = useContext(MemberContext);
  const navigate = useNavigate();

  const toggleMenu = () => {
    console.log("Toggle Menu Clicked"); // デバッグ用ログ
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <nav className="navbar">
      <div className="circleName">ライジング</div>
      <div className="account-name1" onClick={closeMenu}>
        {currentUserInfo ? (
          <span
            onClick={() => navigate(`/memberedit/${currentUserInfo.author.id}`)}
          >
            会員名：{currentUserInfo.accountname}
          </span>
        ) : (
          <span>""</span>
        )}
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776; {/* ハンバーガーアイコン */}
      </div>
      <ul className={`nav-links ${menuActive ? "active" : ""}`}>
        <li onClick={closeMenu}>
          <Link to="/">ホーム</Link>
        </li>
        <li onClick={closeMenu}>
          <Link to="/eventlist">日程</Link>
        </li>

        {currentUserInfo?.administrator && (
          <>
            <li onClick={closeMenu}>
              <Link to="/eventselect">日程入力</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/evenmembermanagement">参加管理</Link>
            </li>
            <li onClick={closeMenu}>
              <Link to="/memberlist">メンバー一覧</Link>
            </li>
          </>
        )}
        <li onClick={closeMenu}>
          <Link to="/link">リンク</Link>
        </li>
        {!isAuth ? (
          <li onClick={closeMenu}>
            <Link to="/login">ログイン</Link>
          </li>
        ) : (
          <>
            <li onClick={closeMenu}>
              <Link to="/logout">ログアウト</Link>
            </li>
          </>
        )}
      </ul>{" "}
      <div className="account-name" onClick={closeMenu}>
        {currentUserInfo ? (
          <span
            onClick={() => navigate(`/memberedit/${currentUserInfo.author.id}`)}
          >
            会員名：{currentUserInfo.accountname}
          </span>
        ) : (
          <span>""</span>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
