import "./Home.scss"; // CSSファイルをインポート

const Home = () => {
  return (
    <>
      <div class="damy"> </div>
      <div className="container-home">
        <img src="/images/raising1.jpg" alt="背景画像" />
        <div className="rising-text">RAISING2004</div>
      </div>
    </>
  );
};

export default Home;
