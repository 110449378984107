// src/components/EventDetail.js
import { EventContext } from "Contexts/EventContext";
import { MemberContext } from "Contexts/MemberContext";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import "./EventDetail.scss";

const EventDetail = () => {
  const { eventList } = useContext(EventContext);
  const { membersList } = useContext(MemberContext);
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [eventMembers, setEventMembers] = useState([]);
  const [userEventParticipation, setUserEventParticipation] = useState({});
  const [participantCounts, setParticipantCounts] = useState({});
  const navigate = useNavigate();
  const [event, setEvent] = useState({
    title: "",
    site: "",
    site_region: "",
    starttime: "",
    duration: "",
    deadline: "",
    court_surface: "",
    court_count: "",
    court_num: "",
    capacity: "",
    map: "",
    detail: "",
    password: "",
    rank: "",
    created_at: "",
    updated_at: "",
  });
  const [administrator, setAdministrator] = useState(false);

  useEffect(() => {
    const adminStatus = localStorage.getItem("administrator") === "true";
    setAdministrator(adminStatus);
  }, []);

  const convertDurationToMinutes = (duration) => {
    if (!duration) {
      return 0;
    }
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    if (
      !eventList ||
      eventList.length === 0 ||
      !membersList ||
      membersList.length === 0
    ) {
      return;
    }

    setEvents(eventList);

    const filteredEvents = eventList.filter((event) => event.id === id);
    if (filteredEvents.length > 0) {
      setEvent(filteredEvents[0]);
    }

    const eventMembersCollection = collection(db, "event_members");
    const fetchEventMembers = async () => {
      const eventMembersSnapshot = await getDocs(eventMembersCollection);
      const eventMembersList = eventMembersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventMembers(eventMembersList);
    };

    fetchEventMembers();

    const user = auth.currentUser;
    if (user) {
      const userId = user.uid;
      setCurrentUser(user);

      const userEventMembers = eventMembers.filter(
        (member) => member.memberId === userId
      );
      const userParticipation = userEventMembers.reduce((acc, member) => {
        acc[member.eventId] = true;
        return acc;
      }, {});
      setUserEventParticipation(userParticipation);

      const isUserMember = membersList.some(
        (member) => member.author.id === userId
      );
      if (!isUserMember) {
        alert(
          "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
        );
        navigate("/member");
      }
    }
  }, [id, navigate, eventList, membersList]);

  useEffect(() => {
    const fetchParticipantCounts = () => {
      const counts = events.reduce((acc, event) => {
        const participants = eventMembers.filter(
          (member) => member.eventId === event.id
        );
        acc[event.id] = participants.length;
        return acc;
      }, {});
      setParticipantCounts(counts);
    };

    fetchParticipantCounts();
  }, [events, eventMembers]);

  const handleJoinEvent = async (eventId) => {
    if (!currentUser) {
      console.log("User is not logged in");
      alert("ログインしてください");
      navigate("/login");
      return;
    }

    const userId = currentUser.uid;

    const eventMembersQuery = query(
      collection(db, "event_members"),
      where("eventId", "==", eventId),
      where("memberId", "==", userId)
    );

    const querySnapshot = await getDocs(eventMembersQuery);

    if (!querySnapshot.empty) {
      alert("このイベントにはすでに参加しています。");
      return;
    }

    const eventDetail = events.find((e) => e.id === eventId);
    if (!eventDetail) {
      alert("イベントが見つかりません");
      return;
    }

    const currentParticipantsCount = participantCounts[eventId] || 0;

    if (currentParticipantsCount >= eventDetail.capacity) {
      alert("定員締め切りのため、参加できません。");
      return;
    }

    const user = membersList.find((member) => member.author.id === userId);
    if (!user) {
      alert(
        "あなたはメンバー名が未登録です。メンバー登録でお名前を登録してください。"
      );
      navigate("/member");
      return;
    }
    const accountname = user.accountname;

    const docRef = await addDoc(collection(db, "event_members"), {
      eventId: eventId,
      memberId: userId,
      accountname: accountname,
    });

    const docId = docRef.id;

    setEventMembers((prevEventMembers) => [
      ...prevEventMembers,
      { eventId, memberId: userId, accountname, id: docId },
    ]);

    setUserEventParticipation((prevParticipation) => ({
      ...prevParticipation,
      [eventId]: true,
    }));

    const eventMembersCollection = collection(db, "event_members");
    const eventMembersSnapshot = await getDocs(eventMembersCollection);
    const eventMembersList = eventMembersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEventMembers(eventMembersList);
  };

  const formatDateWithDayStyle = (date) => {
    if (!(date instanceof Date)) {
      console.error("Invalid date object:", date);
      return "無効な日付";
    }
    const formattedDate = format(date, "yyyy年M月d日（E）", { locale: ja });
    const day = format(date, "E", { locale: ja });

    let dayStyle = "default-text";
    if (day === "日") {
      dayStyle = "red-text";
    } else if (day === "土") {
      dayStyle = "blue-text";
    }

    return (
      <span className="default-text">
        {formattedDate.split("（")[0]}
        <span className={dayStyle}>{`（${formattedDate.split("（")[1]}`}</span>
      </span>
    );
  };

  const getAccountName = (memberId) => {
    const member = membersList.find((m) => m.author.id === memberId);
    return member ? member.accountname : "不明";
  };

  const handleNavigateToCancel = (eventId, memberId) => {
    if (administrator) {
      navigate(`/eventcancel/${eventId}/${memberId}`);
    } else {
      alert("この操作は管理者のみが実行できます。");
    }
  };

  const handleReturn = () => {
    navigate(`/eventlist`);
    return;
  };

  return (
    <>
      <div className="eventDetailContainer">
        <h1>イベント詳細</h1>
        <table className="eventDetailTable">
          <tbody>
            <tr>
              <td>開催日時</td>
              <td>
                {event.starttime && (
                  <>
                    {formatDateWithDayStyle(event.starttime)}
                    {format(event.starttime, "H")}時
                    {format(event.starttime, "m") !== "0" && (
                      <>{format(event.starttime, "m")}分</>
                    )}
                  </>
                )}
                から
                {event.duration && (
                  <>
                    {Math.floor(event.duration / 60)}時間
                    {event.duration % 60 !== 0 && <>{event.duration % 60}分</>}
                  </>
                )}
              </td>
            </tr>
            {/* <tr>
            <td>締切日時</td>
            <td>
              {event.deadline && (
                <>
                  {formatDateWithDayStyle(event.deadline)}
                  {format(event.deadline, "H")}時
                  {format(event.deadline, "m") !== "0" && (
                    <>{format(event.deadline, "m")}分</>
                  )}
                </>
              )}
            </td>
          </tr> */}
            <tr>
              <td>会場</td>
              <td>
                {event.site}（{event.site_region}）
              </td>
            </tr>
            <tr>
              <td>会場の地図</td>
              <td>
                {event.map ? (
                  <a href={event.map} target="_blank" rel="noopener noreferrer">
                    地図を見る
                  </a>
                ) : (
                  "地図情報がありません"
                )}
              </td>
            </tr>
            <tr>
              <td>テニスコート</td>
              <td>
                {event.court_count}面　種類：
                {event.court_surface}
                　コート番号
                {event.court_num}
              </td>
            </tr>
            <tr>
              <td>定員</td>
              <td>{event.capacity}</td>
            </tr>
            <tr>
              <td>備考</td>
              <td>{event.detail}</td>
            </tr>
            <tr>
              <td>参加申込者</td>
              <td>
                <div className="participantsContainer">
                  {eventMembers
                    .filter((member) => member.eventId === event.id)
                    .map((member) => (
                      <div key={member.id}>
                        <button
                          onClick={() =>
                            handleNavigateToCancel(event.id, member.id)
                          }
                          style={{
                            cursor: administrator ? "pointer" : "default",
                          }}
                          disabled={!administrator}
                        >
                          {getAccountName(member.memberId)}{" "}
                          {/* 修正: getAccountName を使ってアカウント名を表示 */}
                        </button>
                      </div>
                    ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>参加申込</td>
              <td>
                {participantCounts[event.id] >= event.capacity ? (
                  <button
                    className="button3"
                    disabled
                    style={{ cursor: "not-allowed" }}
                  >
                    定員締め切り
                  </button>
                ) : userEventParticipation[event.id] ? (
                  <button
                    className="button2"
                    onClick={() => handleJoinEvent(event.id)}
                  >
                    参加申込済
                  </button>
                ) : (
                  <button
                    className="button3"
                    onClick={() => handleJoinEvent(event.id)}
                  >
                    参加する
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="return">
          <button className="return_button" onClick={handleReturn}>
            日程に戻る→
          </button>
        </div>
      </div>
    </>
  );
};

export default EventDetail;
